import React from 'react';
import Modal from './Modal';
import './ConfirmEntryModal.css';

const MoveToQueueModal = ({ onClose, onConfirm }) => {
  return (
    <Modal onClose={onClose}>
      <div className="confirm-entry-modal">
        <h2>Confirm move to queue</h2>
        <p>Are you sure you want to move customer to queue?</p>
        <div className="actions">
          <button onClick={onClose} className="btn cancel-button">Cancel</button>
          <button onClick={onConfirm} className="btn confirm-button">Yes, Move</button>
        </div>
      </div>
    </Modal>
  );
};

export default MoveToQueueModal;