import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './MenuPage.css'; // Include CSS for styling

const MenuPage = ({ handleLogout }) => {
  return (
    <div className="menu-container">
      <div className="menu-item" onClick={() => window.open('/qr-code', '_blank')}>
        <FontAwesomeIcon icon={faQrcode} size="lg" />
        <span style={{ marginLeft: '18px' }}>QR Code</span>
      </div>
      <div className="menu-item" onClick={() => window.open('/business-settings', '_blank')}>
        <FontAwesomeIcon icon={faCog} size="lg" />
        <span>Business Settings</span>
      </div>
      <div className="menu-item" onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
        <span>Logout</span>
      </div>
    </div>
  );
};

export default MenuPage;
