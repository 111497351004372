import React, { useState, useEffect } from 'react';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import './NumberModal.css';

const NumberModal = ({ 
  first_table_vacant_time,  // A
  average_waiting_time,     // B
  max_waiting_number,       // n
  token, 
  queue_id, 
  handleNumberSubmit, 
  onClose 
}) => {
  const [currentA, setCurrentA] = useState(first_table_vacant_time);
  const [currentB, setCurrentB] = useState(average_waiting_time);
  const [currentN] = useState(max_waiting_number);
  const [currentC, setCurrentC] = useState(0); // C will be calculated

  
  useEffect(() => {
    // Ensure currentA, currentB, and currentN are treated as numbers
    const newC = Number(currentA) + (Number(currentB) * (Number(currentN) - 1)); 
    setCurrentC(newC);
  }, [currentA, currentB, currentN]);

  // Function to handle increasing or decreasing A
  const handleAChange = (newA) => {
    setCurrentA(newA);
  };

  // Function to handle increasing or decreasing B
  const handleBChange = (newB) => {
    setCurrentB(newB);
  };

  // Submit the current values of A, B, and the calculated C
  const handleSubmit = () => {
    handleNumberSubmit(queue_id, token, currentB, currentA);
  };

  return (
    <div className="number-modal-content">
      {/* Control for First Table Vacant Time (A) */}
      <div className="field-group">
        <button onClick={() => handleAChange(currentA - 1)} className="number-change-button">
          <FaMinusCircle />
        </button>
        <input
          type="number"
          value={currentA === 0 ? '' : currentA} 
          onChange={(e) => handleAChange(Number(e.target.value))}
          className="number-input"
        />
        <button onClick={() => handleAChange(currentA + 1)} className="number-change-button">
          <FaPlusCircle />
        </button>
        <label>First Table Vacant Time (A)</label>
      </div>

      {/* Control for Average Waiting Time (B) */}
      <div className="field-group">
        <button onClick={() => handleBChange(currentB - 1)} className="number-change-button">
          <FaMinusCircle />
        </button>
        <input
          type="number"
          value={currentB === 0 ? '' : currentB}
          onChange={(e) => handleBChange(Number(e.target.value))}
          className="number-input"
        />
        <button onClick={() => handleBChange(currentB + 1)} className="number-change-button">
          <FaPlusCircle />
        </button>
        <label>Average Waiting Time (B)</label>
      </div>

      {/* Display for Next Waiting Time (C) */}
      <div className="field-group">
        <input
          type="number"
          value={currentC}
          readOnly
          className="number-input"
        />
        <label>Next Waiting Time (C)</label>
      </div>

      {/* Display Formula and Current N */}
      <div className="formula-display">
        <p>Formula: C = A + (B * N)</p>
        <p>Current N (Number of users in queue): {currentN -1}</p>
      </div>

      {/* Submit Button */}
      <button onClick={handleSubmit} className="submit-button">
        Submit
      </button>
    </div>
  );
};

export default NumberModal;
