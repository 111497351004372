import React, { useState, useEffect } from 'react';
import './TableSettingsPage.css';
import QueueService from './QueueService';
import {onMessage } from 'firebase/messaging';
import ReactDOM from 'react-dom'; 
import Message from "./Message";
import { messaging } from "../firebase/firebaseConfig";
const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = `${apiUrl}/business`;


const TableSettingsPage = ({ token, business_id }) => {
  const [tables, setTables] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false); // Toggle dropdown visibility
  const [exitTime, setExitTime] = useState('');
  const [selectedTableId, setSelectedTableId] = useState(null); 
  const [visibleDropdownTableId, setVisibleDropdownTableId] = useState(null);

  onMessage(messaging, (payload) => {
    console.log(payload);
    if (payload.notification.title === 'table_updated'){
      fetchTables(token)
      const body = JSON.parse(payload.notification.body)
      updateNotificationPanel([{"body": `Table ${body.name} status changed to ${body.status}`}]);
    }
  });


  function updateNotificationPanel(notifications) {
    const notificationPanel = document.getElementById('notification-panel');
  
    if (notifications.length === 0) {
      notificationPanel.style.display = 'none';
    } else {
      notificationPanel.style.display = 'block';
    }
  
    notificationPanel.innerHTML = ''; // Clear existing notifications
  
    notifications.forEach((notification, index) => {
      const container = document.createElement('div');
      container.setAttribute('id', `notification-${index}`);
      
      // Create a root for each notification
      const root = ReactDOM.createRoot(container);
  
      root.render(
        <Message
          notification={notification}
          onClick={() => {
            fetchTables(token);
            root.unmount(); // Properly unmount the component
            notificationPanel.removeChild(container); // Remove the container from DOM
          }}
        />
      );
  
      notificationPanel.appendChild(container);
    });
  }

  const fetchTables = async (token) => {
      const res = await QueueService.fetchTables(token);
      setTables(res);
  };
  useEffect(() => {
    fetchTables(token);
  }, [token, business_id]);


  const toggleDropdown = (tableId) => {
    if (visibleDropdownTableId === tableId) {
      setVisibleDropdownTableId(null); // Close the dropdown if already open
    } else {
      setVisibleDropdownTableId(tableId); // Open the dropdown for the selected table
    }
  };
  

  const handleSelectChange = (table, e) => {
    const newTableId = parseInt(e.target.value, 10);  // Get the selected table ID
    setSelectedTableId(newTableId);                   // Update selected table
    handleButtonClick(table, newTableId);          // Call the function with current and new table ID
    setDropdownVisible(false);                        // Close the dropdown after selection
  };


  const handleButtonClick = (table, newTableId) => {
    const data = {
      mapping_id: table.mapping_id,
      old_table_id: table.id,
      table_id: newTableId
    }
    fetch(`${BASE_URL}/update_mapping`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data)
    })
    // Add your custom logic here (e.g., API calls, state updates)
  };

  
  const updateTable = async (id, updatedField) => {
    try {
      const response = await fetch(`${BASE_URL}/add_or_update_business_table?auth_token=${token}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          ...updatedField,
        }),
      });
      const data = await response.json();
      if (data.success) {
        fetchTables(token); // Refresh tables after successful update
      }
    } catch (error) {
      console.error('Error updating table:', error);
    }
  };

  const handleStatusChange = (id, newStatus) => {
    updateTable(id, { status: newStatus });
  };


  const handleExitTimeChange = (event) => {
    setExitTime(event.target.value);
};


  const handleExitTimeBlur = (mapping_id) => {
    const today = new Date();
    const [hours, minutes] = exitTime.split(':');
    const selectedDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours, minutes);
    const epochTime = Math.floor(selectedDateTime.getTime() / 1000);
    const data = {
      mapping_id: mapping_id,
      exit_time: epochTime
    };

    fetch(`${BASE_URL}/update_mapping`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data)
    })
  };
  



  return (
    
    <div className="tables-page">
      <div id="notification-panel" class="notification-panel"></div>
      {tables.length === 0 ? (
        <p>No tables available.</p>
      ) : (
        <table className="tables-table">
          <thead>
            <tr>
              <th>Table Name</th>
              <th>Status</th>
              <th style={{minWidth:'170px'}}>Customer Name</th>
              <th className="no-wrap">Entry Time</th>
              <th>Estimated Vacant Time</th>
              <th>Capacity</th>
            </tr>
          </thead>
          <tbody>
            {tables.map((table) => (
              <tr key={table.id}>
                <td>{table.name}</td>
                <td>
                  <select style={{padding:'5px', fontSize:'14px'}}
                    value={table.status.toUpperCase()} // Ensure status is in uppercase as per backend response
                    onChange={(e) => handleStatusChange(table.id, e.target.value)}
                  >
                    <option value="VACANT">Vacant</option>
                    <option value="OCCUPIED">Occupied</option>
                  </select>
                </td>

                <td className="no-wrap">
                    {table.status === "occupied" && (
                      <>
                       <div class="table-content">
                        <p class="name">{table.customer_name || ''}</p>
                        <button
                          onClick={() => toggleDropdown(table.id)} // Pass table.id to identify the row
                          className="change-table-btn"
                        >
                          <img 
                          src="https://icons.veryicon.com/png/o/miscellaneous/linear-icon-23/another-change-3.png" // Replace with your icon link or local path
                          alt="Change Table Icon"
                          className="change-icon"
                        />
                        </button>
                        </div>
                        {visibleDropdownTableId === table.id && ( // Show dropdown only if the clicked row matches
                          <select
                            onChange={(e) => handleSelectChange(table, e)}
                            className="table-dropdown"
                          >
                            <option value="">Select New Table</option>
                            {tables.map((t) => (
                              <option key={t.id} value={t.id}>
                                {t.name} (Status: {t.status})
                              </option>
                            ))}
                          </select>
                        )}
                        
                      </>
                    )}
                    
                  </td>

                <td>
                <td>
                    {table.entry_time
                      ? new Date(new Date(table.entry_time).getTime() + 5.5 * 60 * 60 * 1000) // Add 5:30 hours for IST
                          .toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) // Display only hours and minutes in AM/PM format
                      : ''}
                  </td>
                </td>
                <td>
                  {table.status === "occupied" && (
                    <input
                      type="time"
                      value={
                        table.estimated_exit_time
                          ? new Date(table.estimated_exit_time).toISOString().slice(11, 16) // Extract only the time (HH:MM)
                          : ''
                      }
                      onChange={(event) => handleExitTimeChange(event)}
                      onBlur={() => handleExitTimeBlur(table.mapping_id)}
                    />
                  )}
                    
                  </td>
                <td>{table.capacity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      
    </div>
  );
};

export default TableSettingsPage;
