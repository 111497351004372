import React from 'react';
import './WaitingScreen.css';

const WaitingScreen = ({business_info, customer_info, setShowModal}) => {
  	return (
    		<div className="iphone-14-15-pro-9">
      			<div className="frame-parent">
        				<div className="waitwhiz-logo-pure-white-backg-parent">
          					<img className="waitwhiz-logo-pure-white-backg-icon" alt="" src="https://i.ibb.co/Nr7qRgH/Waitwhiz-Logo-Pure-White-Background.png" />
          					<img className="comorin-logo-1" alt="" src={business_info.business_metadata.logo_url} />
        				</div>
        				<div className="frame-group">
          					<div className="hi-harman-parent">
            						<b className="hi-harman">Hi {customer_info.customer_name},</b>
            						<b className="hi-harman">You have been added in the queue</b>
          					</div>
          					<div className="frame-container">
            						<div className="users-parent">
										<div className="users-icon">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											  <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z" stroke="#1E1E1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
											</svg>
										  </div>
              							<div className="waiting-no-container">
                								<span>{`Waiting No : `}</span>
                								<span className="span">{customer_info.waiting_number}</span>
              							</div>
            						</div>
            						<div className="users-parent">
									<div className="iconclock">
				  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.7099 15.18L12.6099 13.33C12.0699 13.01 11.6299 12.24 11.6299 11.61V7.51001" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</div>
              							<div className="estimated-wait-time-container">
                								<span>
                  									<span>{`Estimated wait time* : `}</span>
                  									<span className="span">{customer_info.estimated_wait_time_buffer === 0
                      ? `${customer_info.initial_waiting_time} mins`
                      : `${customer_info.initial_waiting_time}-${
						customer_info.initial_waiting_time + customer_info.estimated_wait_time_buffer
                        } mins`}</span>
                								</span>
              							</div>

            						</div>
									<p style={{ margin: '5px 0', marginTop: '5px', marginRight: '0px', marginBottom: '5px', marginLeft: '0px', fontSize: 'x-small' }}>
    *wait time is approximate and may vary depending on the time taken by current dining customers.
</p>
          					</div>
          					<div className="for-menu-click-here-parent">
            						<div className="hi-harman">
              							<span>
                								<b>For Menu</b>
                								<span className="span1">{`, `}</span>
              							</span>
              												
										  <a 
										className="click-here" 
										href={business_info.business_metadata.menu_url} 
										target="_blank" 
										rel="noopener noreferrer"
										>
										Click Here
										</a>
            						</div>
            						<b className="hi-harman">We wish you a wonderful experience</b>
          					</div>
        				</div>
      			</div>
				
				{/* Business Ad Creative */}
				<div className="business-ad-creative">
						{business_info.business_metadata.ad_creative && (
							<img 
							src={business_info.business_metadata.ad_creative} 
							alt="Business Ad Creative" 
							className="ad-creative-img"
							/>
						)}
						</div>

      			
				<div className="button">
  					<div className="star" />
  					<div 
    					className="cancel-queue" 
    					onClick={() => setShowModal(true)}
  					>
    					Cancel Queue
  					</div>
  					<div className="star" />
				</div>
    		</div>
  	);
};

export default WaitingScreen;
