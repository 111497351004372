import React, { useState, useEffect } from 'react';
import QueueService from './QueueService';
import './AllEnteredPage.css';
import './BusinessSettings.css';

const BusinessSettings = ({ token, business_id }) => {
    const [businessInfo, setBusinessInfo] = useState({
        // business_name: '',
        // logo_url: '',
        // total_initial_waiting_time: '',
        // max_waiting_number: '',
        // estimated_wait_time_buffer: ''
    });
    const [loading, setLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        const fetchBusinessInfo = async () => {
            try {
                const response = await QueueService.GetBusinessInfo(business_id); // Assuming business_id is passed internally or via another prop
                setBusinessInfo({
                    // business_name: response.business_name,
                    // logo_url: response.business_metadata.logo_url,
                    menu_url: response.business_metadata.menu_url,
                    ad_creative: response.business_metadata.ad_creative,
                    pos_integrated: response.business_metadata.pos_integrated,
                    pos_api_url: response.business_metadata.pos_api_url
                    // total_initial_waiting_time: response.queue_info[0].total_initial_waiting_time,
                    // max_waiting_number: response.queue_info[0].max_waiting_number,
                    // estimated_wait_time_buffer: response.queue_info[0].estimated_wait_time_buffer
                });
            } catch (error) {
                console.error('Error fetching business info:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchBusinessInfo();
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBusinessInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value
        }));
    };

    const handleSave = async () => {
        try {
            const response = await QueueService.UpdateBusinessInfo(businessInfo, business_id, token); // Call your update API here
            alert('Business information updated successfully!');
            setEditMode(false);
        } catch (error) {
            console.error('Error saving business info:', error);
            alert('Failed to update business information.');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="business-settings">
            <h2>Business Settings</h2>
            <form className="business-settings-form">
                {/* <label>
                    Business Name:
                    <input
                        type="text"
                        name="business_name"
                        value={businessInfo.business_name}
                        onChange={handleInputChange}
                        disabled={!editMode}
                    />
                </label> */}
                <label>
                    Menu URL:
                    <input
                        type="text"
                        name="menu_url"
                        value={businessInfo.menu_url}
                        onChange={handleInputChange}
                        disabled={!editMode}
                    />
                </label>
                <label>
                    Ad Creative:
                    <input
                        type="text"
                        name="ad_creative"
                        value={businessInfo.ad_creative}
                        onChange={handleInputChange}
                        disabled={!editMode}
                    />
                </label>
                {businessInfo.pos_integrated && (
                <label>
                    PejPooja IP:
                    <input
                        type="text"
                        name="pos_api_url"
                        value={businessInfo.pos_api_url}
                        onChange={handleInputChange}
                        disabled={!editMode}
                    />
                </label>
            )}
                {/* <label>
                    Max Waiting Number:
                    <input
                        type="number"
                        name="max_waiting_number"
                        value={businessInfo.max_waiting_number}
                        onChange={handleInputChange}
                        disabled={!editMode}
                    />
                </label> */}
                {/* <label>
                    Estimated Wait Time Buffer:
                    <input
                        type="number"
                        name="estimated_wait_time_buffer"
                        value={businessInfo.estimated_wait_time_buffer}
                        onChange={handleInputChange}
                        disabled={!editMode}
                    />
                </label> */}
                {editMode ? (
                    <button type="button" onClick={handleSave}>
                        Save
                    </button>
                ) : (
                    <button type="button" onClick={() => setEditMode(true)}>
                        Edit
                    </button>
                )}
            </form>
        </div>
    );
};

export default BusinessSettings;