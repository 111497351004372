import React, { useState, useEffect } from 'react';
import Modal from './Modal'; // Assuming you have a Modal component
import './BusinessTablesModal.css';

const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = `${apiUrl}/business`;


const BusinessTablesModal = ({ onClose, onConfirm }) => {
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(true); // For loading state

  // Fetch tables when the modal is opened
  useEffect(() => {
    const fetchTables = async () => {
      try {
        const response = await fetch(`${BASE_URL}/get_business_tables`, {
          method: 'GET', // Specify the method
          credentials: 'include', // Include credentials for cross-origin requests
        }); // Adjust the API endpoint
        const data = await response.json();
        setTables(data.data || []); // Assuming 'tables' is the list in the response
      } catch (error) {
        console.error('Error fetching tables:', error);
      } finally {
        setLoading(false); // Set loading to false when done
      }
    };

    fetchTables();
  }, []); // Empty dependency array to fetch on mount

  const handleConfirm = (tableNo) => {
    onConfirm(tableNo); // Pass the selected table number to the confirmation handler
  };

  return (
    <Modal onClose={onClose}>
      <div className="business-tables-modal">
        <h2>Available Tables</h2>
        {loading ? (
          <p>Loading tables...</p>
        ) : (
          <ul>
            {tables.length > 0 ? (
              tables.map((table) => (
                <li key={table.queue_id} onClick={() => handleConfirm(table.queue_name)}>
                  {table.queue_name}
                </li>
              ))
            ) : (
              <li>No tables available</li>
            )}
          </ul>
        )}
        <button onClick={onClose} className="btn close-button">Close</button>
      </div>
    </Modal>
  );
};

export default BusinessTablesModal;
